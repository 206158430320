// @import "variables";

$font-family-sans-serif: "Roboto";

$blue: #39f;


$secondary: #fff;

$border-radius:               .175rem;
$border-radius-lg:            .2.5rem;
$border-radius-sm:            .1.5rem;

$material-shadow-depth-1: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
$material-shadow-depth-2: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);

.btn {
  //box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  &:hover {
    box-shadow: $material-shadow-depth-1;
  }
  &:active {
    box-shadow: $material-shadow-depth-2;
  }
  &.btn-secondary,
  &.btn-outline-secondary {
    color: #666;
    border-color: #ccc;
    &:hover,
    &:active {
      color: #666;
      background: #fff;
      border-color: #ccc;
    }
  }
  &.btn-outline-secondary {
    &:hover {
      color: #fff;
      background: #666;
      border: 1px solid #666;
    }
  }

  &.btn-light,
  &.btn-outline-light {
    color: #999;
    border-color: #eee;
  }
  &.btn-outline-light {
    &:hover {
      color: #fff;
      background: #999;
      border: 1px solid #999;
    }
  }
  &.btn-link {
    border-color: #eee;
  }
}

@keyframes checking {
  0% {
    width: 2px;
    height: 2px;
    margin-top: -1px;
  },
  30% {
    width: 7px;
    height: 2px;
    margin-top: -1px;
  },
  100% {
    width: 7px;
    height: 15px;
    margin-top: -7.5px;
  }
}

.btn-group-toggle {
  .btn {
    position: relative;
    transition: padding .25s linear;
    &::before {
      position: absolute;
      top: 65%;
      left: 15px;
      display: none;
      width: 2px;
      height: 2px;
      content: " ";
      border-right: solid 2px #28a745;
      border-bottom: solid 2px #28a745;
      transform: rotate(45deg) translate(-50%, -50%);
      animation-duration: .5s;
    }
    &.active {
      padding-left: 35px;
      &::before {
        display: block;
        width: 7px;
        height: 15px;
        margin-top: -7.5px;
        animation-name: checking;
      }
    }
  }
}

$nav-tabs-link-active-border-color: none;
.nav-tabs {
  .nav-link {
    padding: 15px;
  }
  .nav-link:not(.disabled) {
    position: relative;
    &:hover {
      border-color: transparent transparent transparent;
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      content: " ";
      background: #dee2e6;
      transition: transform .25s ease-out;
      transform: scaleX(0);
      transform-origin: bottom right;
    }
  }
  .nav-link.active {
    // .nav-item.show .nav-link {
    position: relative;
    &:hover {
      border-color: transparent transparent transparent;
    }
    &::after {
      background: transparent;// rgba(0, 0, 0, .3);
    }
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      content: " ";
      background: $blue;
    }
  }
  .dropdown-toggle {
    &::after {
      left: 0;
      margin-left: 0;
      border: none;
    }
    &::before {
      position: absolute;
      top: 50%;
      right: .255em;
      display: block;
      width: 0;
      height: 0;
      margin-top: -2.5px;
      content: " ";
      border-top: 5px solid $blue;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
}

.dropdown-menu.show {
  box-shadow: $material-shadow-depth-2;
}

:not(.dropleft) {
  :not(.btn-secondary).dropdown-toggle-split::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1px;
    height: 100%;
    content: " ";
    background: rgba(0, 0, 0, .1);
  }
}
.dropleft {
  .dropdown-toggle::before {
    top: 50%;
    left: 50%;
    margin-top: -2.5px;
    margin-left: -2.5px;
  }
  :not(.btn-secondary).dropdown-toggle.dropdown-toggle-split::after {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 1px;
    height: 100%;
    content: " ";
    background: rgba(0, 0, 0, .1);
    border: none;
  }
}

.modal {
  .modal-content {
    border-radius: 3px;
    box-shadow: $material-shadow-depth-2;
  }
}
